// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Nav
 *
 * @format
 */

.nav {
    padding: 20px 0;
    background-color: var(--nav-bg);
    border-bottom: 1px solid var(--nav-border);
    color: var(--nav-text);
    letter-spacing: normal;
}

.nav-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 30px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.logo {
    margin-right: auto;
    color: var(--nav-text);
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
}

.logo strong {
    font-weight: 700;
}

.nav-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;
    column-gap: 40px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.nav-list__link {
    color: var(--nav-text);
    transition: opacity 0.2s ease-in;
}

.nav-list__link:hover {
    opacity: 0.8;
}

.nav-list__link--active {
    position: relative;
}

.nav-list__link--active::before {
    content: '';

    position: absolute;
    left: 0;
    top: 100%;

    display: block;
    height: 2px;
    width: 100%;
    background-color: var(--accent);
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/style.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,eAAe;IACf,+BAA+B;IAC/B,0CAA0C;IAC1C,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,kCAAkC;AACtC;;AAEA;IACI,sBAAsB;IACtB,gCAAgC;AACpC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;;IAEX,kBAAkB;IAClB,OAAO;IACP,SAAS;;IAET,cAAc;IACd,WAAW;IACX,WAAW;IACX,+BAA+B;AACnC","sourcesContent":["/**\r\n * Nav\r\n *\r\n * @format\r\n */\r\n\r\n.nav {\r\n    padding: 20px 0;\r\n    background-color: var(--nav-bg);\r\n    border-bottom: 1px solid var(--nav-border);\r\n    color: var(--nav-text);\r\n    letter-spacing: normal;\r\n}\r\n\r\n.nav-row {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    align-items: center;\r\n    column-gap: 30px;\r\n    row-gap: 20px;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.logo {\r\n    margin-right: auto;\r\n    color: var(--nav-text);\r\n    font-size: 24px;\r\n    font-family: 'Poppins', sans-serif;\r\n}\r\n\r\n.logo strong {\r\n    font-weight: 700;\r\n}\r\n\r\n.nav-list {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    row-gap: 10px;\r\n    align-items: center;\r\n    column-gap: 40px;\r\n    font-size: 16px;\r\n    font-weight: 500;\r\n    font-family: 'Poppins', sans-serif;\r\n}\r\n\r\n.nav-list__link {\r\n    color: var(--nav-text);\r\n    transition: opacity 0.2s ease-in;\r\n}\r\n\r\n.nav-list__link:hover {\r\n    opacity: 0.8;\r\n}\r\n\r\n.nav-list__link--active {\r\n    position: relative;\r\n}\r\n\r\n.nav-list__link--active::before {\r\n    content: '';\r\n\r\n    position: absolute;\r\n    left: 0;\r\n    top: 100%;\r\n\r\n    display: block;\r\n    height: 2px;\r\n    width: 100%;\r\n    background-color: var(--accent);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
