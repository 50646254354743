/** @format */

import resume from '../../CV_Daniil_Tarasenko_Junior_Front_end_developer.pdf';
import React from 'react';
import './style.css';

const Header = () => {
    return (
        <header className='header'>
            <div className='header__wrapper'>
                <h1 className='header__title'>
                    <strong>
                        Hi, my name is <em>Daniil</em>
                    </strong>
                    <br />I am a frontend developer
                </h1>
                <div className='header__text'>
                    <p>
                        with passion for learning and creating imaginable
                        projects
                    </p>
                </div>
                <a
                    href={resume}
                    className='btn'
                    value='download'
                    download='Resume'
                >
                    Download CV
                </a>
            </div>
        </header>
    );
};

export default Header;
