// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Projects
 *
 * @format
 */

.projects {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
}

.project {
    max-width: 370px;
    background-color: var(--project-card-bg);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
}

.project__img {
    border-radius: 10px;
}

.project__title {
    padding: 15px 20px 25px;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    color: var(--project-card-text);
}
`, "",{"version":3,"sources":["webpack://./src/components/projects/style.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,wCAAwC;IACxC,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,+BAA+B;AACnC","sourcesContent":["/**\r\n * Projects\r\n *\r\n * @format\r\n */\r\n\r\n.projects {\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-wrap: wrap;\r\n    column-gap: 30px;\r\n    row-gap: 30px;\r\n}\r\n\r\n.project {\r\n    max-width: 370px;\r\n    background-color: var(--project-card-bg);\r\n    box-shadow: var(--box-shadow);\r\n    border-radius: 10px;\r\n}\r\n\r\n.project__img {\r\n    border-radius: 10px;\r\n}\r\n\r\n.project__title {\r\n    padding: 15px 20px 25px;\r\n    font-weight: 700;\r\n    font-size: 24px;\r\n    line-height: 1.3;\r\n    color: var(--project-card-text);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
