/** @format */

import project_1Big from './../img/projects/01-big.jpg';

import project_2Big from './../img/projects/02-big.jpg';

import project_3Big from './../img/projects/03-big.jpg';
import React from 'react';

const projects = [
    {
        title: 'WebSite of API Movies',
        skills: 'HTML, CSS, React',
        img: project_1Big,
        imgBig: project_1Big,
        gitHubLink: 'https://github.com/Bachamak/react-movies',
    },
    {
        title: 'Traveling List',
        skills: 'HTML, CSS, React',
        img: project_2Big,
        imgBig: project_2Big,
        gitHubLink: 'https://github.com/Bachamak/travelling-list',
    },
    {
        title: 'Eat-N-Split',
        skills: 'HTML, CSS, React',
        img: project_3Big,
        imgBig: project_3Big,
        gitHubLink: 'https://github.com/Bachamak/eat-n-split',
    },
];

export { projects };
