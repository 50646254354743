// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../img/header-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Header
 *
 * @format
 */

.header {
    padding: 40px 0;
    min-height: 695px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--header-bg);
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;

    color: var(--header-text);
    text-align: center;
}

.header__wrapper {
    padding: 0 15px;
    max-width: 660px;
}

.header__title {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.4;
}

.header__title strong {
    font-size: 60px;
    font-weight: 700;
}

.header__title em {
    font-style: normal;
    color: var(--accent);
}

.header__text {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 1.333;
}

.header__text p + p {
    margin-top: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/style.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,eAAe;IACf,iBAAiB;;IAEjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,kCAAkC;IAClC,yDAAkD;IAClD,4BAA4B;IAC5B,qBAAqB;IACrB,kCAAkC;;IAElC,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["/**\r\n * Header\r\n *\r\n * @format\r\n */\r\n\r\n.header {\r\n    padding: 40px 0;\r\n    min-height: 695px;\r\n\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n    background-color: var(--header-bg);\r\n    background-image: url('./../../img/header-bg.png');\r\n    background-repeat: no-repeat;\r\n    background-size: auto;\r\n    background-position: center center;\r\n\r\n    color: var(--header-text);\r\n    text-align: center;\r\n}\r\n\r\n.header__wrapper {\r\n    padding: 0 15px;\r\n    max-width: 660px;\r\n}\r\n\r\n.header__title {\r\n    margin-bottom: 20px;\r\n    font-size: 40px;\r\n    font-weight: 700;\r\n    line-height: 1.4;\r\n}\r\n\r\n.header__title strong {\r\n    font-size: 60px;\r\n    font-weight: 700;\r\n}\r\n\r\n.header__title em {\r\n    font-style: normal;\r\n    color: var(--accent);\r\n}\r\n\r\n.header__text {\r\n    margin-bottom: 40px;\r\n    font-size: 18px;\r\n    line-height: 1.333;\r\n}\r\n\r\n.header__text p + p {\r\n    margin-top: 0.5em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
