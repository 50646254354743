// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Footer
 *
 * @format
 */

.footer {
    margin-top: auto;
    padding: 40px 0 30px;
    background-color: var(--footer-bg);
    color: var(--footer-text);
}

.footer__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 22px;
}

.social {
    display: flex;
    column-gap: 40px;
    align-items: center;
}

.copyright {
    font-size: 16px;
}

.copyright p {
    margin-top: 0.4rem;
}

.footer {
    padding: 40px 0 30px;
}

.footer__wrapper {
    row-gap: 20px;
}

/* Social */

.social {
    column-gap: 20px;
}

.social__item {
    width: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/style.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;IACI,gBAAgB;IAChB,oBAAoB;IACpB,kCAAkC;IAClC,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;AACjB;;AAEA,WAAW;;AAEX;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":["/**\r\n * Footer\r\n *\r\n * @format\r\n */\r\n\r\n.footer {\r\n    margin-top: auto;\r\n    padding: 40px 0 30px;\r\n    background-color: var(--footer-bg);\r\n    color: var(--footer-text);\r\n}\r\n\r\n.footer__wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    row-gap: 22px;\r\n}\r\n\r\n.social {\r\n    display: flex;\r\n    column-gap: 40px;\r\n    align-items: center;\r\n}\r\n\r\n.copyright {\r\n    font-size: 16px;\r\n}\r\n\r\n.copyright p {\r\n    margin-top: 0.4rem;\r\n}\r\n\r\n.footer {\r\n    padding: 40px 0 30px;\r\n}\r\n\r\n.footer__wrapper {\r\n    row-gap: 20px;\r\n}\r\n\r\n/* Social */\r\n\r\n.social {\r\n    column-gap: 20px;\r\n}\r\n\r\n.social__item {\r\n    width: 28px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
