/** @format */

import './../css/main.css';
import React from 'react';

const Contacts = () => {
    return (
        <>
            <main className='section'>
                <div className='container'>
                    <h1 className='title-1'>Contacts</h1>

                    <hr />

                    <ul className='content-list'>
                        <li className='content-list__item'>
                            <h2 className='title-2'>My location</h2>
                            <p>Dnipro, Ukraine</p>
                        </li>
                        <li className='content-list__item'>
                            <h2 className='title-2'>
                                Telegram / WhatsApp / Viber
                            </h2>
                            <button>
                                <a href='tel:+79051234567'>+ (380) 672535127</a>
                            </button>
                        </li>
                        <li className='content-list__item'>
                            <h2 className='title-2'>Email</h2>
                            <button>
                                <a href='mailto:webdev@protonmail.com'>
                                    bachamack@gmail.com
                                </a>
                            </button>
                        </li>
                    </ul>
                </div>
            </main>
        </>
    );
};

export default Contacts;
