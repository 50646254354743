/** @format */

// import * as React from 'react';
// import ReactDOM from 'react-dom';
// import { css, jsx } from '@emotion/react';
import { useParams } from 'react-router-dom';
import ButtonGitHub from '../components/buttonGitHub/BtnGitHub';
import { projects } from '../helpers/projectsList';

const Project = () => {
    const { id } = useParams();
    const project = projects[id];

    return (
        <main className='section'>
            <div className='container'>
                <div className='project-details'>
                    <h1 className='title-1'>{project.title}</h1>

                    <img
                        src={project.imgBig}
                        alt={project.title}
                        className='project-details__cover'
                    />

                    <div className='project-details__desc'>
                        <p>Skills: {project.skills}</p>
                    </div>

                    {project.gitHubLink && (
                        <ButtonGitHub link={project.gitHubLink} />
                    )}
                </div>
            </div>
        </main>
    );
};

export default Project;
