/** @format */

import './style.css';

import vk from './../../img/icons/vk.svg';
import instagram from './../../img/icons/instagram.svg';
import twitter from './../../img/icons/twitter.svg';
import github from './../../img/icons/gitHub.svg';
import linkedIn from './../../img/icons/linkedIn.svg';
import React from 'react';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container'>
                <div className='footer__wrapper'>
                    <ul className='social'>
                        <li className='social__item'>
                            <a href='https://vk.com/id315567038'>
                                <img
                                    src={vk}
                                    alt='Link'
                                />
                            </a>
                        </li>
                        <li className='social__item'>
                            <a href='https://www.instagram.com/bachamak_/'>
                                <img
                                    src={instagram}
                                    alt='Link'
                                />
                            </a>
                        </li>
                        <li className='social__item'>
                            <a href='https://twitter.com/TDRomanovich'>
                                <img
                                    src={twitter}
                                    alt='Link'
                                />
                            </a>
                        </li>
                        <li className='social__item'>
                            <a href='https://github.com/Bachamak'>
                                <img
                                    src={github}
                                    alt='Link'
                                />
                            </a>
                        </li>
                        <li className='social__item'>
                            <a href='https://www.linkedin.com/in/daniil-tarasenko/'>
                                <img
                                    src={linkedIn}
                                    alt='Link'
                                />
                            </a>
                        </li>
                    </ul>
                    <div className='copyright'>
                        <p>© 2023 tarasenkodr.com</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
